<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="tenders"
      sort-by="name"
      class="elevation-1"
      :search="search"
      :custom-filter="filterText"
    >
      <template v-slot:top>
        <v-toolbar flat class="pt-2">
          <v-col cols="12" sm="5" class="mt-5">
            <v-toolbar-title
              class="error--text text-uppercase font-weight-bold text--darken-1 justify-center"
            >
              <v-icon class="mr-2 mt-n1" color="error"
                >mdi-shopping</v-icon
              >List of Tenders
            </v-toolbar-title>
            <p class="grey--text ml-8 mt-n2">List of all available tenders</p>
            <v-divider class="mx-4" inset vertical></v-divider>
          </v-col>
          <v-col cols="12" sm="3" class="mt-5">
            <v-select
              :items="tenderCategories"
              label="Select Type"
              item-text="title"
              item-value="id"
              small
              dense
              persistent-hint
              return-object
              single-line
              color="primary"
              outlined
            >
            </v-select>
          </v-col>

          <v-col cols="12" sm="3" class="mt-5">
            <v-text-field
              label="Search"
              v-model="search"
              append-icon="mdi-magnify"
              dense
              small
              color="primary"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="1" align="right">
            <v-row justify="center">
              <v-dialog v-model="dialog" scrollable max-width="950" persistent>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2 text-capitalize"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>mdi-plus-thick</v-icon> New
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="error--text text--darken-1">{{
                    formTitle
                  }}</v-card-title>
                  <v-divider></v-divider>
                  <v-card-text style="height: 500px">
                    <v-container>
                      <v-form ref="form" v-model="valid">
                        <v-row>
                          <v-col cols="12" sm="4" md="4">
                            <v-text-field
                              v-model="editedItem.tender_no"
                              label="Tender Number"
                              :rules="rules.required"
                              color="grey"
                              dense
                              outlined
                              class="mb-n3"
                            >
                              <template v-slot:label>
                                <span class="input__label"
                                  >Tender Number
                                  <v-icon small color="error" class="mt-n2"
                                    >mdi-star-outline</v-icon
                                  ></span
                                >
                              </template>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="8" md="8" dense>
                            <v-text-field
                              v-model="editedItem.title"
                              label="Tender Title"
                              :rules="rules.required"
                              color="grey"
                              dense
                              outlined
                              class="mb-n3"
                            >
                              <template v-slot:label>
                                <span class="input__label"
                                  >Tender Title
                                  <v-icon small color="error" class="mt-n2"
                                    >mdi-star-outline</v-icon
                                  ></span
                                >
                              </template>
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" sm="12" md="12" class="my-n5">
                            <v-textarea
                              v-model="editedItem.description"
                              outlined
                              name="input-7-4"
                              label="Tender Details"
                              color="grey"
                              dense
                              :rules="rules.required"
                              class="mb-n3"
                            >
                              <template v-slot:label>
                                <span class="input__label"
                                  >Tender Details
                                  <v-icon small color="error" class="mt-n2"
                                    >mdi-star-outline</v-icon
                                  ></span
                                >
                              </template>
                            </v-textarea>
                          </v-col>

                          <v-col cols="12" sm="4" md="4">
                            <v-select
                              v-model="editedItem.category"
                              :items="tenderCategories"
                              label="Select Category"
                              item-text="title"
                              item-value="id"
                              color="grey"
                              dense
                              outlined
                              :rules="rules.select"
                            >
                              <template v-slot:label>
                                <span class="input__label"
                                  >Tender Category
                                  <v-icon small color="error" class="mt-n2"
                                    >mdi-star-outline</v-icon
                                  ></span
                                >
                              </template>
                            </v-select>
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                            <v-select
                              v-model="editedItem.application_steps"
                              :items="tender_steps"
                              label="Application Steps"
                              color="grey"
                              item-value="id"
                              item-text="name"
                              dense
                              outlined
                              :rules="rules.select"
                            >
                              <template v-slot:label>
                                <span class="input__label"
                                  >Application Steps
                                  <v-icon small color="error" class="mt-n2"
                                    >mdi-star-outline</v-icon
                                  ></span
                                >
                              </template>
                            </v-select>
                          </v-col>

                          <v-col cols="12" sm="4" md="4">
                            <v-text-field
                              v-model="editedItem.budget"
                              label="Budget"
                              outlined
                              dense
                              color="grey"
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" sm="4" md="4">
                            <v-text-field
                              v-model="editedItem.application_fee"
                              label="Application Fee"
                              outlined
                              dense
                              color="grey"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="4" md="4">
                            <v-select
                              v-model="editedItem.status"
                              :items="tender_status"
                              label="Status"
                              color="grey"
                              dense
                              outlined
                              :rules="rules.select"
                              item-value="id"
                              item-text="name"
                            >
                              <template v-slot:label>
                                <span class="input__label"
                                  >Tender Status
                                  <v-icon small color="error" class="mt-n2"
                                    >mdi-star-outline</v-icon
                                  ></span
                                >
                              </template></v-select
                            >
                          </v-col>
                          <!-- Closing date  -->
                          <v-col cols="12" sm="4" md="4">
                            <v-menu
                              ref="menu_closing_date"
                              v-model="menu_closing_date"
                              :close-on-content-click="true"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="editedItem.closing_date"
                                  :value="computedClosingDate"
                                  label="Closing Date"
                                  persistent-hint
                                  append-icon="mdi-calendar"
                                  v-bind="attrs"
                                  v-on="on"
                                  outlined
                                  dense
                                  color="grey"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="closingDateWatcher"
                                no-title
                                @change="menu_closing_date = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <!-- Clarification end date  -->
                          <v-col cols="12" sm="4" md="4">
                            <v-menu
                              ref="menu_clarification_date"
                              v-model="menu_clarification_date"
                              :close-on-content-click="true"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="editedItem.clarification_end_date"
                                  :value="computedClarificationDate"
                                  label="Clarification End Date"
                                  persistent-hint
                                  append-icon="mdi-calendar"
                                  v-bind="attrs"
                                  v-on="on"
                                  outlined
                                  dense
                                  color="grey"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="clarificationDateWatcher"
                                no-title
                                @change="menu_clarification_date = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <!-- Evaluation end date  -->
                          <v-col cols="12" sm="4" md="4">
                            <v-menu
                              ref="menu_clarification_date"
                              v-model="menu_evaluation_date"
                              :close-on-content-click="true"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="editedItem.evaluation_end_date"
                                  :value="computedEvaluationDate"
                                  label="Evaluation End Date"
                                  persistent-hint
                                  append-icon="mdi-calendar"
                                  v-bind="attrs"
                                  v-on="on"
                                  outlined
                                  dense
                                  color="grey"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="evaluationDateWatcher"
                                no-title
                                @change="menu_evaluation_date = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>

                          <!-- Approval end date  -->
                          <v-col cols="12" sm="4" md="4">
                            <v-menu
                              ref="menu_approval_date"
                              v-model="menu_approval_date"
                              :close-on-content-click="true"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="editedItem.tender_approval_date"
                                  :value="computedApprovalDate"
                                  label="Tender Approval Date"
                                  persistent-hint
                                  append-icon="mdi-calendar"
                                  v-bind="attrs"
                                  v-on="on"
                                  outlined
                                  dense
                                  color="grey"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="approvalDateWatcher"
                                no-title
                                @change="menu_approval_date = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>

                          <!-- required docs  -->
                          <v-col cols="12" sm="12" md="12" class="mt-n5">
                            <v-select
                              v-model="editedItem.required_documents"
                              :value="editedItem.required_documents"
                              :rules="rules.select2"
                              :items="required_documents"
                              label="Mandatory Documents"
                              item-text="name"
                              item-value="id"
                              multiple
                              outlined
                              chips
                              color="grey"
                              item-color="grey"
                              class="my-1 mx-1"
                            >
                              <template v-slot:label>
                                <span class="input__label"
                                  >Mandatory Documents
                                  <v-icon small color="error" class="mt-n2"
                                    >mdi-star-outline</v-icon
                                  ></span
                                >
                              </template>
                              <template v-slot:prepend-item>
                                <v-list-item ripple @click="toggleRequired">
                                  <v-list-item-action>
                                    <v-icon
                                      :color="
                                        editedItem.required_documents.length > 0
                                          ? 'error'
                                          : ''
                                      "
                                    >
                                      {{ iconRequired }}
                                    </v-icon>
                                  </v-list-item-action>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                              </template>
                              <template v-slot:append-item>
                                <v-divider class="mb-2"></v-divider>
                                <v-list-item disabled>
                                  <v-list-item-avatar color="grey lighten-3">
                                    <v-icon> mdi-file-outline </v-icon>
                                  </v-list-item-avatar>
                                  <v-list-item-content
                                    v-if="likesAllRequiredDocument"
                                  >
                                    <v-list-item-title>
                                      All Documents selected for mandatory
                                      fields!
                                    </v-list-item-title>
                                  </v-list-item-content>

                                  <v-list-item-content
                                    v-else-if="likesSomerequired_documents"
                                  >
                                    <v-list-item-title>
                                      Document Count
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                      {{ editedItem.required_documents.length }}
                                    </v-list-item-subtitle>
                                  </v-list-item-content>

                                  <v-list-item-content v-else>
                                    <v-list-item-title>
                                      Which documents are mandatory for this
                                      tender?
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                      Go ahead, make a selection above!
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-select>
                          </v-col>

                          <!-- technical docs -->
                          <v-col cols="12" sm="12" md="12" class="mt-n5">
                            <v-select
                              v-model="editedItem.technical_documents"
                              :value="editedItem.technical_documents"
                              :rules="rules.select2"
                              :items="technical_documents"
                              label="Technical Documents"
                              item-text="name"
                              item-value="id"
                              multiple
                              outlined
                              chips
                              color="grey"
                              item-color="grey"
                              class="my-1 mx-1"
                            >
                              <template v-slot:label>
                                <span class="input__label"
                                  >Technical Documents
                                  <v-icon small color="error" class="mt-n2"
                                    >mdi-star-outline</v-icon
                                  ></span
                                >
                              </template>
                              <template v-slot:prepend-item>
                                <v-list-item ripple @click="toggleTechnical">
                                  <v-list-item-action>
                                    <v-icon
                                      :color="
                                        editedItem.technical_documents.length >
                                        0
                                          ? 'error'
                                          : ''
                                      "
                                    >
                                      {{ iconTechnical }}
                                    </v-icon>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      Select All
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                              </template>
                              <template v-slot:append-item>
                                <v-divider class="mb-2"></v-divider>
                                <v-list-item disabled>
                                  <v-list-item-avatar color="grey lighten-3">
                                    <v-icon> mdi-file-outline </v-icon>
                                  </v-list-item-avatar>
                                  <v-list-item-content
                                    v-if="likesAllTechnicalDocument"
                                  >
                                    <v-list-item-title>
                                      All Documents selected for technical
                                      fields!
                                    </v-list-item-title>
                                  </v-list-item-content>

                                  <v-list-item-content
                                    v-else-if="likesSometechnical_documents"
                                  >
                                    <v-list-item-title>
                                      Document Count
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                      {{
                                        editedItem.technical_documents.length
                                      }}
                                    </v-list-item-subtitle>
                                  </v-list-item-content>

                                  <v-list-item-content v-else>
                                    <v-list-item-title>
                                      Which documents are technical for this
                                      tender?
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                      Go ahead, make a selection above!
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-select>
                          </v-col>

                          <!-- financial docs -->
                          <v-col cols="12" sm="12" md="12" class="mt-n5">
                            <v-select
                              v-model="editedItem.financial_documents"
                              :value="editedItem.financial_documents"
                              :rules="rules.select2"
                              :items="financial_documents"
                              label="Financial Documents"
                              item-text="name"
                              item-value="id"
                              multiple
                              outlined
                              chips
                              color="grey"
                              item-color="grey"
                              class="my-1 mx-1"
                            >
                              <template v-slot:label>
                                <span class="input__label">
                                  Financial Documents
                                  <v-icon small color="error" class="mt-n2"
                                    >mdi-star-outline</v-icon
                                  ></span
                                >
                              </template>
                              <template v-slot:prepend-item>
                                <v-list-item ripple @click="toggleFinancial">
                                  <v-list-item-action>
                                    <v-icon
                                      :color="
                                        editedItem.financial_documents.length >
                                        0
                                          ? 'error'
                                          : ''
                                      "
                                    >
                                      {{ iconFinancial }}
                                    </v-icon>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      Select All
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                              </template>
                              <template v-slot:append-item>
                                <v-divider class="mb-2"></v-divider>
                                <v-list-item disabled>
                                  <v-list-item-avatar color="grey lighten-3">
                                    <v-icon> mdi-file-outline </v-icon>
                                  </v-list-item-avatar>
                                  <v-list-item-content
                                    v-if="likesAllFinancialDocument"
                                  >
                                    <v-list-item-title>
                                      All Documents selected for financial
                                      fields!
                                    </v-list-item-title>
                                  </v-list-item-content>

                                  <v-list-item-content
                                    v-else-if="likesSomefinancial_documents"
                                  >
                                    <v-list-item-title>
                                      Document Count
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                      {{
                                        editedItem.financial_documents.length
                                      }}
                                    </v-list-item-subtitle>
                                  </v-list-item-content>

                                  <v-list-item-content v-else>
                                    <v-list-item-title>
                                      Which documents are financial for this
                                      tender?
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                      Go ahead, make a selection above!
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      class="text-capitalize mx-1"
                      dark
                      @click="close"
                    >
                      Cancel <v-icon small>mdi-cancel</v-icon>
                    </v-btn>
                    <v-btn
                      color="primary"
                      dark
                      class="text-capitalize mx-1"
                      :loading="loading"
                      @click="save"
                    >
                      Submit <v-icon>mdi-content-save-outline</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-col>
        </v-toolbar>
        <v-progress-linear
          indeterminate
          color="primary"
          v-if="loading"
        ></v-progress-linear>
        <v-divider></v-divider>
      </template>

      <template v-slot:[`item.closing_date`]="{ item }">
        {{ item.closing_date }}
      </template>
      <template v-slot:[`item.documents`]="{ item }">
        {{ item.name }}
        <a
          v-for="(item, i) in item.documents"
          :key="i"
          link
          v-bind:href="docURI"
          >({{ i + 1 }}) {{ item.name }} ,
        </a>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <a v-if="item.status.slug == 'open'" class="success--text">
          {{ item.status.name }}
        </a>
        <a v-else class="grey--text">{{ item.status.name }}</a>
      </template>
      <template v-slot:[`item.company`]="{ item }">
        {{ getCompanyName(item.company) }}
      </template>
      <template v-slot:[`item.tender_no`]="{ item }">
        <a
          @click.stop="tenderPreview(item)"
          class="primary--text text--darken-1 text-decoration-underline"
          >{{ item.tender_no }}</a
        >
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-row align="center" justify="right">
        <v-btn class="alt" depressed fab x-small dark @click="editItem(item)">
            <v-icon small>mdi-pencil-outline</v-icon>
          </v-btn>
          <v-btn
            class="error ml-1"
            depressed
            fab
            x-small
            dark
            @click="deleteItem(item)"
          >
            <v-icon small>mdi-delete-outline</v-icon>
          </v-btn>
        </v-row>
      </template>

      <template v-slot:[`item.bidders_count`]="{ item }">
        <v-btn class="mx-auto" :elevation="0" dark small rounded color="primary">
          <span class="font-weight-bold button"> {{ item.bidders_count }}</span>
        </v-btn>
      </template>

      <template v-slot:[`item.clarifications_count`]="{ item }">
        <v-btn
          v-if="item.clarifications_count>0"
          class="mx-auto"
          :elevation="0"
          dark
          small
          rounded
          color="warning"
          @click="clarificationPreview(item)"
        >
          <span class="font-weight-bold button">
            {{
              item.clarifications_answered + "/" + item.clarifications_count
            }}</span
          >
        </v-btn>
        <v-btn
          v-else
          class="mx-auto"
          :elevation="0"
          dark
          small
          rounded
          color="warning"
          @click="emptyClarificationPreview(item)"
        >
          <span class="font-weight-bold button">
            {{
              item.clarifications_answered + "/" + item.clarifications_count
            }}</span
          >
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import TenderService from "../../services/tender.service";
// import UpdateProfile from "@/components/UpdateProfile";
import moment from "moment";
import User from "../../models/user";

export default {
  components: {
    // UpdateProfile,
  },
  data: (vm) => ({
    name: "MarketPlace",
    tenders: [],
    required_documents: [],
    technical_documents: [],
    financial_documents: [],
    editedIndex: -1,
    loading: true,
    closingDateWatcher: new Date().toISOString().substr(0, 10),
    menu_closing_date: false,
    clarificationDateWatcher: new Date().toISOString().substr(0, 10),
    menu_clarification_date: false,
    evaluationDateWatcher: new Date().toISOString().substr(0, 10),
    menu_evaluation_date: false,
    approvalDateWatcher: new Date().toISOString().substr(0, 10),
    menu_approval_date: false,

    editedItem: {
      tender_no:
        "ABC/" +
        Math.floor(Math.random() * 9999 + 1000) +
        "/" +
        moment().format("yyyy"),
      title: "",
      description: "",
      category: "",
      budget: "",
      application_fee: "",
      application_steps: "",
      closing_date: vm.formatDate(new Date().toISOString().substr(0, 10)),
      clarification_end_date: vm.formatDate(
        new Date().toISOString().substr(0, 10)
      ),
      evaluation_end_date: vm.formatDate(
        new Date().toISOString().substr(0, 10)
      ),
      tender_approval_date: vm.formatDate(
        new Date().toISOString().substr(0, 10)
      ),
      status: "",
      required_documents: [],
      technical_documents: [],
      financial_documents: [],
      required_document_ids: [],
      technical_document_ids: [],
      financial_document_ids: [],
    },
    defaultItem: {
      tender_no:
        "ABC/" +
        Math.floor(Math.random() * 9999 + 1000) +
        "/" +
        moment().format("yyyy"),
      title: "",
      description: "",
      category: "",
      budget: "",
      application_fee: "",
      application_steps: "",
      closing_date: vm.formatDate(new Date().toISOString().substr(0, 10)),
      clarification_end_date: vm.formatDate(
        new Date().toISOString().substr(0, 10)
      ),
      evaluation_end_date: vm.formatDate(
        new Date().toISOString().substr(0, 10)
      ),
      tender_approval_date: vm.formatDate(
        new Date().toISOString().substr(0, 10)
      ),
      status: "",
      required_documents: [],
      technical_documents: [],
      financial_documents: [],
      required_document_ids: [],
      technical_document_ids: [],
      financial_document_ids: [],
    },

    tender_steps: [
      {
        id: 1,
        name: "One step",
      },
      {
        id: 2,
        name: "Two step",
      },
      {
        id: 3,
        name: "Three step",
      },
    ],

    tender_status: [
      {
        id: 1,
        name: "Draft",
      },
      {
        id: 2,
        name: "Open",
      },
      {
        id: 3,
        name: "Closed",
      },
      {
        id: 4,
        name: "Evaluated",
      },
    ],

    page: "Tenders",
    selected: [0],
    search: "",
    files: "",
    docURI:
      "https://portal.ufaa.go.ke/frontend/web/index.php/page/download-all?tenderID=37",
    tenderCategories: [],
    user: new User("", ""),
    remember: 0,
    timeout: 2000,
    dialog: false,
    countries: ["Kenya", "Uganda", "Tanzania", "Rwanda"],
    owners: [1, 2, 3, 4],
    valid: true,
    rules: {
      select: [(v) => !!v || "An item should be selected"],
      select2: [(v) => v.length > 0 || "Atleast one item should be selected"],
      required: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
    },
    headers: [
      {
        text: "Tender No",
        align: "start",
        filterable: true,
        value: "tender_no",
      },
      {
        text: "Tender Details",
        value: "title",
      },
      {
        text: "Category",
        value: "category.title",
      },
      {
        text: "Closing Date",
        value: "closing_date",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Clarifications",
        value: "clarifications_count",
      },
      {
        text: "Bidders Count",
        value: "bidders_count",
      },
      {
        text: "Actions",
        align: "start",
        value: "actions",
        sortable: false,
        width: "10%",
      },
    ],
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New" : "Edit";
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    computedClosingDate() {
      return this.formatDate(this.editedItem.closing_date);
    },
    computedClarificationDate() {
      return this.formatDate(this.editedItem.clarification_end_date);
    },
    computedEvaluationDate() {
      return this.formatDate(this.editedItem.evaluation_end_date);
    },
    computedApprovalDate() {
      return this.formatDate(this.editedItem.tender_approval_date);
    },
    // required docs
    likesAllRequiredDocument() {
      return (
        this.editedItem.required_documents.length ===
        this.required_documents.length
      );
    },
    likesSomerequired_documents() {
      return (
        this.editedItem.required_documents.length > 0 &&
        !this.likesAllRequiredDocument
      );
    },
    iconRequired() {
      if (this.likesAllRequiredDocument) return "mdi-close-box";
      if (this.likesSomerequired_documents) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    // technical docs
    likesAllTechnicalDocument() {
      return (
        this.editedItem.technical_documents.length ===
        this.technical_documents.length
      );
    },
    likesSometechnical_documents() {
      return (
        this.editedItem.technical_documents.length > 0 &&
        !this.likesAllTechnicalDocument
      );
    },
    iconTechnical() {
      if (this.likesAllTechnicalDocument) return "mdi-close-box";
      if (this.likesSometechnical_documents) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    // financial docs
    likesAllFinancialDocument() {
      return (
        this.editedItem.financial_documents.length ===
        this.financial_documents.length
      );
    },
    likesSomefinancial_documents() {
      return (
        this.editedItem.financial_documents.length > 0 &&
        !this.likesAllFinancialDocument
      );
    },
    iconFinancial() {
      if (this.likesAllFinancialDocument) return "mdi-close-box";
      if (this.likesSomefinancial_documents) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    closingDateWatcher() {
      this.editedItem.closing_date = this.formatDate(this.closingDateWatcher);
    },
    clarificationDateWatcher() {
      this.editedItem.clarification_end_date = this.formatDate(
        this.clarificationDateWatcher
      );
    },
    evaluationDateWatcher() {
      this.editedItem.evaluation_end_date = this.formatDate(
        this.evaluationDateWatcher
      );
    },
    approvalDateWatcher() {
      this.editedItem.tender_approval_date = this.formatDate(
        this.approvalDateWatcher
      );
    },
  },

  created() {
    this.getAllTenders();
    this.getCategories();
    this.getAllRequiredDocuments();
    this.getAllTechnicalDocuments();
    this.getAllFinancialDocuments();
  },

  methods: {
    setDBDateFormat() {
      // Set date formt
      this.editedItem.closing_date = this.reverseFormatDate(
        this.editedItem.closing_date
      );
      this.editedItem.clarification_end_date = this.reverseFormatDate(
        this.editedItem.clarification_end_date
      );
      this.editedItem.evaluation_end_date = this.reverseFormatDate(
        this.editedItem.evaluation_end_date
      );
      this.editedItem.tender_approval_date = this.reverseFormatDate(
        this.editedItem.tender_approval_date
      );
    },
    revertDBDateFormat() {
      // Set date formt
      this.editedItem.closing_date = this.formatDate(
        this.editedItem.closing_date
      );
      this.editedItem.clarification_end_date = this.formatDate(
        this.editedItem.clarification_end_date
      );
      this.editedItem.evaluation_end_date = this.formatDate(
        this.editedItem.evaluation_end_date
      );
      this.editedItem.tender_approval_date = this.formatDate(
        this.editedItem.tender_approval_date
      );
    },
    filterText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    // required docs
    toggleRequired() {
      this.$nextTick(() => {
        if (this.likesAllRequiredDocument) {
          this.editedItem.required_documents = [];
        } else {
          this.editedItem.required_documents = this.required_documents.slice();
        }
      });
    },
    getAllRequiredDocuments() {
      return TenderService.getAllRequiredDocuments().then(
        (response) => {
          if (response.status == 200) {
            this.required_documents = response.data.data;
          } else {
            this.tenders = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.tenders = [];
          console.log(error);
        }
      );
    },
    // technical docs
    toggleTechnical() {
      this.$nextTick(() => {
        if (this.likesAllTechnicalDocument) {
          this.editedItem.technical_documents = [];
        } else {
          this.editedItem.technical_documents = this.technical_documents.slice();
        }
      });
    },
    getAllTechnicalDocuments() {
      return TenderService.getAllTechnicalDocuments().then(
        (response) => {
          if (response.status == 200) {
            this.technical_documents = response.data.data;
          } else {
            this.tenders = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.tenders = [];
          console.log(error);
        }
      );
    },
    // financial docs
    toggleFinancial() {
      this.$nextTick(() => {
        if (this.likesAllFinancialDocument) {
          this.editedItem.financial_documents = [];
        } else {
          this.editedItem.financial_documents = this.financial_documents.slice();
        }
      });
    },
    getAllFinancialDocuments() {
      return TenderService.getAllFinancialDocuments().then(
        (response) => {
          if (response.status == 200) {
            this.financial_documents = response.data.data;
          } else {
            this.tenders = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.tenders = [];
          console.log(error);
        }
      );
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    reverseFormatDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("-");
      console.log(`${year}-${month}-${day}`);
      return `${year}-${month}-${day}`;
    },
    getCompanyName(company) {
      return company.company_name;
    },
    tenderPreview(item) {
      this.$router.push("/tender-preview/" + item.id);
    },
    clarificationPreview(item) {
      this.$router.push("/clarification/" + item.id);
    },
    emptyClarificationPreview(){
      alert('No clarification requested!');
    },
    getCategories() {
      return TenderService.getCategories().then(
        (response) => {
          if (response.status == 200) {
            this.tenderCategories = response.data.data;
          } else {
            this.tenders = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.tenders = [];
          console.log(error);
        }
      );
    },
    getAllTenders() {
      return TenderService.getAllTenders().then(
        (response) => {
          if (response.status == 200) {
            this.tenders = response.data.data;
            this.loading = false;
          } else {
            this.tenders = [];
            this.loading = false;
            console.log(response.data.error);
          }
        },
        (error) => {
          this.tenders = [];
          console.log(error);
        }
      );
    },
    editItem(item) {
      this.editedIndex = this.tenders.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // Format date
      this.dialog = true;
    },
    deleteItem(item) {
      const index = this.tenders.indexOf(item);
      var deleteUser = confirm("Are you sure you want to delete this item?");
      if (deleteUser && this.tenders.splice(index, 1)) {
        TenderService.deleteTender(item).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.close();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            }
            console.log(error);
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.$refs.form.validate();
      if (this.valid) {
        this.loading = true;
        this.setDBDateFormat();
        // edit
        if (this.editedIndex > -1) {
          // convert back to db date
          Object.assign(this.tenders[this.editedIndex], this.editedItem);
          if (typeof this.editedItem.category != "number") {
            this.editedItem.category = this.editedItem.category.id;
          }
          if (typeof this.editedItem.status != "number") {
            this.editedItem.status = this.editedItem.status.id;
          }
          // check docs
          try {
            if (this.editedItem.required_documents[0].id) {
              this.editedItem.required_documents = this.editedItem.required_document_ids;
            }
            if (this.editedItem.technical_documents[0].id) {
              this.editedItem.technical_documents = this.editedItem.technical_document_ids;
            }
            if (this.editedItem.financial_documents[0].id) {
              this.editedItem.financial_documents = this.editedItem.financial_document_ids;
            }
          } catch (error) {
            console.log(error);
          }

          TenderService.updateTender(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.getAllTenders();
                this.loading = false;
                this.close();
              } else {
                this.$store.dispatch(
                  "alert/error",
                  "Invalid data, please check the form try again!"
                  // response.response.statusText
                );
                this.revertDBDateFormat();
                this.getAllTenders();
                this.loading = false;
              }
            },
            (error) => {
              console.log(error);
              this.revertDBDateFormat();
              this.getAllTenders();
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
              }
              this.$store.dispatch("alert/error", error.response.data.message);
              this.loading = false;
            }
          );
        } else {
          // create
          TenderService.storeTender(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.tenders.push(this.editedItem);
                this.$store.dispatch(
                  "alert/success",
                  "Tender created successfully"
                );
                this.getAllTenders();
                this.loading = false;
                this.close();
              } else {
                this.revertDBDateFormat();
                this.$store.dispatch(
                  "alert/error",
                  response.response.statusText || "Internal error!"
                );
                this.loading = false;
              }
            },
            (error) => {
              this.revertDBDateFormat();
              this.loading = false;
              console.log(error);
              if (error.response.status == 422) {
                this.$store.dispatch("alert/error", error);
              }
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      }
    },
  },
};
</script>
